









































import {AffiliatePlatform, ImageUpload} from "@/store/types";
import Vue from "vue";
import { mapGetters } from "vuex";
import {v4 as uuidv4} from "uuid";

export default Vue.extend({
  name: "AffiliatePlatformsForm",

  props: {
    platform: { type: Object, default: null }
  },

  data() {
    return {
      loading: false,
      options: [
        { text: "Sim", value: true },
        { text: "Não", value: false }
      ],
      payload: {
        _id: "",
        name: "",
        logo: "",
        key: "",
        active: true,
        created_at: new Date(),
        updated_at: new Date(),
      } as AffiliatePlatform
    };
  },

  computed: {
    ...mapGetters({
      apps: "mobileAppsModule/getApps",
    })
  },

  mounted() {
    if (this.platform) {
      Object.assign(this.payload, this.platform)
    }
  },

  methods: {
    save(): void {
      if (!this.validateForm()) return;
      this.loading = true;

      if (this.platform) {
        this.payload.updated_at = new Date()
        this.$store
            .dispatch("affiliatePlatformsModule/update", this.payload)
            .then(() => this.$emit("close"))
            .catch((e) => this.$store.dispatch("app/showIsError", e.message))
            .finally(() => this.loading = false);
      } else {
        this.$store
            .dispatch("affiliatePlatformsModule/create", this.payload)
            .then(() => this.$emit("close"))
            .catch((e) => this.$store.dispatch("app/showIsError", e.message))
            .finally(() => this.loading = false);
      }
    },

    validateForm(): boolean {
      if (!this.payload.name) {
        this.showError("Insira o Nome da Plataforma!");
        return false;
      }
      if (!this.payload.logo) {
        this.showError("Insira uma Imagem da Plataforma!");
        return false;
      }
      if (!this.payload.key) {
        this.showError("Insira o Nome (Key) da Plataforma!");
        return false;
      }
      return true;
    },

    showError(message: string): void {
      this.$store.dispatch("app/showCustomError", message);
    },

    getFile(file: File) {
      if (!file.type.includes("image")) {
        return this.$store.dispatch("app/showCustomError", "Arquivo não Suportado!");
      }

      const extension = file.name.split(".")[1];

      const reader = new FileReader();
      reader.onload = async ({ target }) => {
        const image: ImageUpload = {
          folder: "/platforms",
          filename: `${uuidv4()}.${extension}`,
          file: new Uint8Array(target?.result as ArrayBuffer),
          metadata: {
            contentType: file.type,
            size: file.size,
          }
        }

        this.$store.dispatch("uploader/upload", image)
            .then(url => {
              this.payload.logo = url
            })
      };
      reader.readAsArrayBuffer(file);
    }
  }
});
