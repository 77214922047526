



































import Vue from "vue";
import { mapGetters } from "vuex";
import FullScreenDialog from "@/components/FullScreenDialog.vue";
import AffiliatePlatformsForm from "./components/AffiliatePlatformsForm.vue";
import AffiliatePlatformsTable from "./components/AffiliatePlatformsTable.vue";
import AffiliatePlatformFilters from "./components/AffiliatePlatformsFilters.vue";

import { AffiliatePlatform } from "@/store/types";

export default Vue.extend({
  name: "AffiliatePlatformsPage",

  components: {
    FullScreenDialog,
    AffiliatePlatformsForm,
    AffiliatePlatformsTable,
    AffiliatePlatformFilters
  },

  data: () => ({
    fullScreenDialog: false,
    platform: null as unknown | AffiliatePlatform,
  }),

  computed: {
    ...mapGetters({
      platforms: "affiliatePlatformsModule/getPlatforms",
      total: "affiliatePlatformsModule/getTotal",
      loading: "affiliatePlatformsModule/getLoading"
    })
  },

  created() {
    this.$store.dispatch("affiliatePlatformsModule/fetch")
  },

  methods: {
    onUpdate(platform: AffiliatePlatform): void {
      this.$store.dispatch("affiliatePlatformsModule/update", platform);
    },

    editCol(platform: AffiliatePlatform): void {
      this.platform = platform;
      this.openFullScreenDialog();
    },

    deleteCol(platform: AffiliatePlatform): void {
      this.$store.dispatch("affiliatePlatformsModule/archive", platform._id);
    },

    fetch(payload: { page: number, pageSize: number }): void {
      this.$store.dispatch("affiliatePlatformsModule/fetch", payload);
    },

    openFullScreenDialog(): void {
      this.fullScreenDialog = true;
    },

    closeFullScreenDialog(): void {
      this.fullScreenDialog = false;
      this.platform = null;
    },
  }
})
